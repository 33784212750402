(function($){
	$( document ).ready(function() {
		console.log( "ready!" );
			// $('a[href*="#"]').on('click', function(e){
			// 	// e.preventDefault();
			// 	console.log('hashtag clicked and now scrolling');
			// 	$('html, body').animate({
			// 		scrollTop: $( $.attr(this, 'href') ).offset().top
			// 	}, 500);
			// 	return false;
			// }); 

		$('.js-cookie-toggle').on('click', function(e){
			e.preventDefault();
			$('.cookie').removeClass('visited', 'opt-out', 'opt-in');
		});


	});
})(jQuery); 